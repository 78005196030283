import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgbdPrintComponent } from './ngbd-print.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [NgbdPrintComponent],
  imports: [
    CommonModule,
    // PdfViewerModule
    NgxExtendedPdfViewerModule
  ],
  exports: [NgbdPrintComponent]
})
export class NgbdPrintModule { }
