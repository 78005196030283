import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss']
})
export class PdfviewerComponent implements OnInit {
  
  @Input() pdfSrc: String;

  constructor() { }

  ngOnInit(): void {
  }

}
