import { Injectable } from '@angular/core';
import { FunctionService } from 'src/app/service/function.service';
import { AuthenticationService } from 'src/app/service/authentication.service';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  /*  {
   id: 'navigation',
   title: 'Navigation',
   type: 'group',
   icon: 'icon-navigation',
   children: [
     {
       id: 'dashboard',
       title: 'Trang chủ',
       type: 'item',
       url: '/dashboard/default',
       icon: 'feather icon-home',
       classes: 'nav-item',
     }
   ]
 },   */

  //Long 7/4/2020
  {
    id: 'grhethong',
    title: 'Quản trị hệ thống',
    type: 'group',
    //icon: 'icon-pages',
    children: [
      {
        id: 'system',
        title: 'Cấu hình hệ thống',
        type: 'collapse',
        icon: 'feather icon-lock',
        children: [
          {
            id: 'sys_usergroup',
            title: 'Nhóm người dùng',
            type: 'item',
            url: '/system/sysusergroup'
          },
          {
            id: 'sys_user',
            title: 'Người dùng',
            type: 'item',
            url: '/system/sysuser'            
          },
          {
            id: 'sys_account',
            title: 'Tài khoản khai thác dữ liệu',
            type: 'item',
            url: '/system/sysaccount'            
          },
          {
            id: 'sys_config',
            title: 'Cấu hình hệ thống',
            type: 'item',
            url: '/system/config'
          },
          {
            id: 'sys_configthongtinketnoi',
            title: 'Cấu hình thông tin kết nối',
            type: 'item',
            url: '/system/cauhinhthongtinketnoi'
          },
          {
            id: 'sys_configsothutu',
            title: 'Cấu hình số thứ tự',
            type: 'item',
            url: '/system/cauhinhsothutu'
          },
          {
            id: 'sys_history',
            title: 'Nhật ký truy cập',
            type: 'item',
            url: '/system/syshistory'            
          },
          {
            id: 'sys_historyapi',
            title: 'Nhật ký khai thác dữ liệu',
            type: 'item',
            url: '/system/syshistoryapi'            
          },
        ]
      },
      {
        id: 'danhmuc', //'basic',
        title: 'Danh mục',
        type: 'collapse',
        // type: 'group', icon: 'feather icon-box',
        icon: 'fas fa-list',
        children: [
          
        ]

      }
    ]
  } 
];

@Injectable()
export class NavigationItem {
  constructor(private authenticationService: AuthenticationService, private httpClientService: FunctionService) { }

  async get() {
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser == null) return [];   
    return await this.httpClientService.functiongroupmenu(currentUser.user.username);
  }

}
