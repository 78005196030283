import { Component, OnInit } from '@angular/core';
import { SysUserGroup } from 'src/app/model/Sysusergroup';
import { HttpClientService } from 'src/app/service/http-client.service';


@Component({
  selector: 'app-cbsysusergroup',
  templateUrl: './cbsysusergroup.component.html',
  styleUrls: ['./cbsysusergroup.component.scss']
})
export class CbsysusergroupComponent implements OnInit {
  sysusergroup : Array<SysUserGroup>;
  sysusergroup1 : Array<SysUserGroup>;
  constructor(
    private httpClientService: HttpClientService
  ) { }

  ngOnInit(): void {
    this.refreshData();
  }
  refreshData() {
    this.httpClientService.getSysUserGroup().subscribe(
      response => this.handleSuccessfulResponse(response),
    );
  }
  handleSuccessfulResponse(response) {
      this.sysusergroup = response;
  }
}
