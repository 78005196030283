import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbsysusergroupComponent } from './cbsysusergroup.component';
import { SharedModule } from '../../shared.module';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CbsysusergroupComponent],
  exports: [CbsysusergroupComponent]
})
export class CbsysusergroupModule { }
