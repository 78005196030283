import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfviewerComponent } from './pdfviewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';


@NgModule({
  declarations: [PdfviewerComponent],
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule
  ],
  exports: [PdfviewerComponent]
})
export class PdfviewerModule { }
