import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ngbd-print',
  templateUrl: './ngbd-print.component.html',
  styleUrls: ['./ngbd-print.component.scss']
})
export class NgbdPrintComponent implements OnInit {

  @Input() pdfSrc: String;
  
  constructor(
    public activeModal: NgbActiveModal) { }   

  ngOnInit(): void {
    
  }


}
