import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DattaConfig } from '../app-config';
import { SysFunctionRoleChange } from '../model/sysfunctionrolechange';
import { SysFunctionAction } from '../model/sysfunctionaction';
import { SysFunctionDTO } from '../model/sysfunctiondto';
import { SysFunctionRole } from '../model/sysfunctionrole';
import { SysFunction } from '../model/sysfunction';
import { SysFunctionGroup } from '../model/sysfunctiongroup';
import { SysUserJoin } from '../model/sysuserjoin';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  public dattaConfig: any;
  token: string;
  httpOptions: any;


  constructor(private httpClient: HttpClient) {
    this.dattaConfig = DattaConfig.config;

  }

  ngOnInit(): void {
   
  }

  searchSysUser(...field: any) {
    let pars = {
      params: {
        groupid: field[0] === undefined || field[0] === 0 ? '' : field[0],
        userid: field[1] === undefined || field[1] === 0 ? '' : field[1]
      }
    };
    return this.httpClient.get<SysUserJoin[]>(this.dattaConfig['httplink'] + '/sysUser/searchbykey', pars);
  }

  getSysFunctionGroups(){
    return this.httpClient.get<SysFunctionGroup[]>(this.dattaConfig['httplink'] + '/sysfunctiongroup');
  }
  
  addSysFunctionGroup(entity: SysFunctionGroup) {
    return this.httpClient.post<SysFunctionGroup>(this.dattaConfig['httplink'] + '/sysfunctiongroup/add', entity);
  }

  updateSysFunctionGroup(entity: SysFunctionGroup) {
    return this.httpClient.put<SysFunctionGroup>(this.dattaConfig['httplink'] + '/sysfunctiongroup/update', entity);
  }

  deleteSysFunctionGroup(id) {
    return this.httpClient.delete<SysFunctionGroup>(this.dattaConfig['httplink'] + '/sysfunctiongroup/' + id);
  }
  
  getSysFunctions(...field: any){
    let pars = {
      params: {
        functiongroupid: field[0] === undefined || field[0] === null  ? '' : field[0],
        functionfatherid: field[1] === undefined || field[1] === null ? '' : field[1],
        type: field[2] === undefined || field[2] === null ? '' : field[2]
      }
    };
    return this.httpClient.get<SysFunction[]>(this.dattaConfig['httplink'] + '/sysfunction', pars);
  }

  getSearchSysFunctions(...field: any){
    let pars = {
      params: {
        functiongroupid: field[0] === undefined || field[0] === 0  ? '' : field[0],
        functionfatherid: field[1] === undefined || field[1] === 0 ? '' : field[1],
        type: field[2] === undefined || field[2] === null ? '' : field[2]
      }
    };
    return this.httpClient.get<SysFunctionDTO[]>(this.dattaConfig['httplink'] + '/sysfunction/search', pars);
  }

  getSysFunctionsGroupById(functiongroupid: number, ...field: any){
    let pars = {
      params: {
        type: field[0] === undefined || field[0] === null ? '' : field[0]      
      }
    };
    return this.httpClient.get<SysFunction[]>(this.dattaConfig['httplink'] + `/sysfunction/findfunctiongroupbyid/${functiongroupid}`, pars);
  }
  
  getSysFunctionsFatherById(functionfatherid: number, ...field: any){
    let pars = {
      params: {
        type: field[0] === undefined ? '' : field[0]
      }
    };
    return this.httpClient.get<SysFunction[]>(this.dattaConfig['httplink'] + `/sysfunction/findfunctionfatherbyid/${functionfatherid}`, pars);
  }
  
  addSysFunction(entity: SysFunction) {
    return this.httpClient.post<SysFunction>(this.dattaConfig['httplink'] + '/sysfunction/add', entity);
  }

  updateSysFunction(entity: SysFunction) {
    return this.httpClient.put<SysFunction>(this.dattaConfig['httplink'] + '/sysfunction/update', entity);
  }

  deleteSysFunction(id) {
    return this.httpClient.delete<SysFunction>(this.dattaConfig['httplink'] + '/sysfunction/' + id);
  }

  getSysFunctionRoles(){
    return this.httpClient.get<SysFunctionRole[]>(this.dattaConfig['httplink'] + '/sysfunctionrole');
  }
  
  addSysFunctionRole(entity: SysFunctionRole) {
    return this.httpClient.post<SysFunctionRole>(this.dattaConfig['httplink'] + '/sysfunctionrole/add', entity);
  }

  updateSysFunctionRole(entity: SysFunctionRole) {
    return this.httpClient.put<SysFunctionRole>(this.dattaConfig['httplink'] + '/sysfunctionrole/update', entity);
  }

  deleteSysFunctionRole(id) {
    return this.httpClient.delete<SysFunctionRole>(this.dattaConfig['httplink'] + '/sysfunctionrole/' + id);
  }

  getSysFunctionActions(){
    return this.httpClient.get<SysFunctionAction[]>(this.dattaConfig['httplink'] + '/sysfunctionaction');
  }
  
  getSysFunctionActionsByUser(id: number){
    return this.httpClient.get<SysFunctionDTO[]>(this.dattaConfig['httplink'] + `/sysfunctionaction/functionuserbyid/${id}`);
  }
  
  getSysFunctionActionsByRoleId(id: number, ...field: any){
    let pars = {
      params: {
        userid: field[0] === undefined  || field[0] === 0 ? '' : field[0]
      }
    };
    return this.httpClient.get<SysFunctionDTO[]>(this.dattaConfig['httplink'] + `/sysfunctionaction/functionrolebyid/${id}`, pars);
  }

  addSysFunctionAction(entity: SysFunctionAction[]) {
    return this.httpClient.post(this.dattaConfig['httplink'] + '/sysfunctionaction/add', entity);
  }

  addSysFunctionActionByUser(entity: SysFunctionAction[]) {
    return this.httpClient.post(this.dattaConfig['httplink'] + '/sysfunctionaction/useradd', entity);
  }
  
  updateSysFunctionAction(entity: SysFunctionAction) {
    return this.httpClient.put<SysFunctionAction>(this.dattaConfig['httplink'] + '/sysfunctionaction/update', entity);
  }

  deleteSysFunctionAction(id) {
    return this.httpClient.delete<SysFunctionAction>(this.dattaConfig['httplink'] + '/sysfunctionaction/' + id);
  }
  
  functiongroupmenu(userid: string){
    return this.httpClient.get<any[]>(this.dattaConfig['httplink'] + `/sysfunctionaction/${userid}`).toPromise();    
  }

  searchSysFunctionAction(...field: any){
    let pars = {
      params: {
        groupid: field[0] === undefined || field[0] === 0  ? '' : field[0],
        userid: field[1] === undefined || field[4] === 0 ? '' : field[1]
      }
    };
    return this.httpClient.get<SysFunctionRoleChange[]>(this.dattaConfig['httplink'] + '/sysUser/functionactionbykey', pars);
  }

  deleteSysFunctionRoleChange(id) {
    return this.httpClient.delete<SysFunctionAction>(this.dattaConfig['httplink'] + '/sysfunctionaction/user/' + id);
  }
}
 
